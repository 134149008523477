.title {
  @include media-query($on-mobile) {
    font-size: 3.5rem;
    left: 0;
    margin: auto;
    right: 0;
    text-align: center;
  };
  font-family: $title-font;
  font-size: 4.5rem;
}

.subtitle {
  font-family: $accent-font;
  font-size: 2rem;

  &::after {
    border-bottom: 2px solid $train-yellow;
    content: '';
    display: block;
    width: 70%;
  }

  &.center-border {
    &::after {
      margin: 0 auto;
    }
  }
}

.accent {
  font-family: $accent-font;
  line-height: 2rem;
}

.bold {
  font-weight: 800;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
