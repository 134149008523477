//* Container
.container {
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
}

//* Row
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

//* Extra small devices
.extra-small,
.extra-small-1,
.extra-small-2,
.extra-small-3,
.extra-small-4,
.extra-small-5,
.extra-small-6,
.extra-small-7,
.extra-small-8,
.extra-small-9,
.extra-small-10,
.extra-small-11,
.extra-small-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.extra-small {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.extra-small-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.extra-small-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.extra-small-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.extra-small-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.extra-small-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.extra-small-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.extra-small-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.extra-small-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.extra-small-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.extra-small-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.extra-small-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.extra-small-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.order-2 {
  order: 2;
}

//* Extra small devices and above
@media only screen and (min-width: 48em) {
  .small,
  .small-1,
  .small-2,
  .small-3,
  .small-4,
  .small-5,
  .small-6,
  .small-7,
  .small-8,
  .small-9,
  .small-10,
  .small-11,
  .small-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .small {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .small-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .small-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .small-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .small-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .small-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .small-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .small-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .small-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .small-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .small-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .small-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .small-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .small-offset-2 {
    margin-left: 16.667%;
  }

  .order-2 {
    order: initial;
  }
}
