//* Colors
$gray: #dfdfdf;
$gray-light: #f9f9f9;
$gray-mid: #dcdcdc;
$gray-dark: #f9f9f9;
$border-color: #dcdcdc;
$blue-light: #b3fdff;
$blue-dark: #0b01e3;
$yellow: #fdffb3;
$white: #fff;
$black: #000;
$train-red: #e84807;
$train-red-dark: #b93603;
$train-yellow: #ffb000;
$train-yellow-dark: #c08403;
$train-blue: #02219e;
$black: #000;
$card-shadow-25: rgba(0, 0, 0, .25);
$card-shadow-21: rgba(0, 0, 0, .21);

//* Fonts
$title-font: 'Great Vibes', Times, cursive;
$text-font: 'Raleway', Arial, sans-serif;
$accent-font: 'Saira Extra Condensed', Arial, sans-serif;

//* Spacing
$spacing-unit: 1.25rem;
$content-width: 72.5rem;

//* Breakpoint
$on-laptop: 64rem;
$on-mobile: 48rem;

//* Media Screen Mixin
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

//* Clearfix
%clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body {
  overflow-x: hidden;
}

body,
h1,
h2 {
  font-family: $text-font;
  margin: 0;
  padding: 0;
}

input,
textarea,
button {
  border: 1px solid $black;
  border-radius: 4px;
  font-family: $text-font;
  font-size: 1rem;
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-indent: 5px;
  width: 100%;

  &.error {
    border: 1px solid $train-red;
  }

  &.button {
    cursor: pointer;
    font-size: 1.2rem;
  }
}

button {
  appearance: none;
}

a {
  color: $train-red-dark;
  text-decoration: none;

  &:visited {
    color: $train-red-dark;
  }

  &:hover {
    opacity: .8;
  }
}

@import './colors';
@import './utilities';
@import './grid';
@import './typography';
@import './header';
@import './card';
@import './visibility';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


