.banner {
  @include media-query($on-mobile) {
    background-position: center top;
  };
  background-image: url('../assets/images/traindoor.jpg');
  background-position: center 30%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 23rem;
  max-width: 100%;
}

.header-image {
  @include media-query($on-mobile) {
    left: 0;
    margin: auto;
    right: 0;
  };
  border: 6px $white solid;
  border-radius: 50%;
  max-height: 18rem;
  max-width: 18rem;
  padding: 0;
  position: absolute;
  right: 0;
  top: 7rem;
  z-index: 100;
}

.header-text {
  color: $white;
  position: absolute;
  text-shadow: 4px 4px $black;
  top: 17rem;
  z-index: 200;
}

.icon-container {
  display: flex;

  & > a {
    align-self: flex-start;
    margin-right: 40px;
  }
}
