//* Alignment
.center {
  margin: 0 auto;
}

.margin-horizontal {
  margin: 3rem 0;
}

.padding-horizontal {
  padding: 2rem 0;
}

.margin {
  &.single {
    &.bottom {
      margin-bottom: 1rem;
    }

    &.top {
      margin-top: 1rem;
    }

    &.left {
      margin-left: 1rem;
    }
  }
}

.align-center {
  align-items: center;
}

.vertical-align {
  align-items: center;
  justify-content: center;
}


//* Position
.relative {
  position: relative;
}

.block {
  display: block;
}

//* Responsive Images
.responsive-image {
  display: block;
  max-width: 100%;
}

.full-width {
  width: 100%;
}

//* Skip to main content for a11y purposes.
.skip {
  background: $white;
  height: 1px;
  left: -1000px;
  padding: 10px;
  position: absolute;
  top: -1000px;
  white-space: nowrap;
  width: 1px;
  z-index: 1;

  &:focus {
    color: $black;
    font-weight: 800;
    height: auto;
    left: 0;
    overflow: hidden;
    top: 0;
    width: auto;
    z-index: 999;
  }
}

//* Visibility
.invisible {
  visibility: hidden;
}

//* Lists
.list {
  padding-left: 1rem;
  text-align: left;

  h2 {
    border-bottom: 1px solid $gray-light;
  }

  li {
    margin-bottom: 1rem;
  }

  .list-title {
    font-size: 1rem;
    font-weight: 800;
  }
}


//* Slick Slider
.slick-slider {
  margin-bottom: 2rem;

  img {
    margin: 0 auto;
  }
}

//* Ensures the current slide is clickable.
.slick-active {
  z-index: 999;
}
