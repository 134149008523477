.background-gray {
  background-color: $gray-light;
}

.background-yellow {
  background-color: $train-yellow;
}

.white {
  color: $white;
}

.blue {
  color: $train-blue;
}

.red {
  color: $train-red;
}

.red-dark {
  color: $train-red-dark;
}

.yellow {
  color: $train-yellow;
}

.yellow-dark {
  color: $train-yellow-dark;
}

.border-gray {
  &.bottom {
    border-bottom: 1px solid $border-color;
  }

  &.top {
    border-top: 1px solid $border-color;
  }
}
